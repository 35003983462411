<template>
    <div>
        <strong>Quote Item Upload</strong>
        <br>
        Quote: {{ data.quote_id }}
        <br>
        Company: {{ data.company_name }}
        <br>
        Job Tag: {{ data.job_tag }}
        <br>
        <br>

        <div class="form-group">
            <label>Drawing Type</label>
            <select class="form-control" v-model="state.reference2ID">
                <option v-if="isStaff" value="1">Custom Existing</option>
                <option v-if="isStaff" value="2">Custom New</option>
                <option v-if="isStaff" value="3">Custom 3D</option>
                <option v-if="isStaff" value="5">Customer Drawing</option>
                <option v-if="isStaff" value="15">Measurement</option>
                <option v-if="isStaff" value="44">Custom Conforming Stamp</option>
                <option value="14">3rd Party Submittal</option>
            </select>
        </div>
        <div class="form-group">
            <label>Item</label>
            <select class="form-control" v-model="state.referenceID">
                <option v-for="curb in appState.fileUploadContextData.metadata.curbs"
                        v-bind:key="curb.curb_data.quote_curb_id"
                        :value="curb.curb_data.quote_curb_id"
                >
                    {{ curb.curb_data.line }}{{ curb.curb_data.qty }} {{ curb.curb_data.nomenclature }} {{ curb.curb_data.tag }}
                </option>
            </select>
        </div>
    </div>
</template>

<script>
    import { fileUploadStore } from "@/store/FileUpload.store";
    import appStore from "@/store/App.store";

    export default {
        name: "QuoteCurb",
        data() {
            return {
                state: fileUploadStore.state,
                appState: appStore.state,
            }
        },
        created() {
            /** @see curb_order_base.php */
            fileUploadStore.initialize(1);
            fileUploadStore.state.reference2ID = 1;
            fileUploadStore.state.allowAttach = true;
        },
        computed: {
            isStaff: function () {
                return appStore.isStaffExcludingShop();
            },
            data: function () {
                if (appStore.state.fileUploadContextData.metadata) {
                    return appStore.state.fileUploadContextData.metadata;
                }

                return appStore.state.fileUploadContextData.data;
            }
        },
      methods: {},
        mounted() {
            this.state.referenceID = this.data.curbs[0].curb_data.quote_curb_id;
            this.state.quoteID = this.data.quote_id;
        },
        deactivated() {
            delete this.state.quoteID;
        }
    }
</script>

<style scoped>

</style>